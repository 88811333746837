import type { ListingResult } from "@shopware-pwa/types";

export const useShopwarePickupLocations = (
	geoLocation?: Ref<{ lat: number; lng: number }>,
	onlyPickupLocations: boolean = false
) => {
	const { apiInstance } = useShopwareContext();

	const response = useData(async () => {
		const filters = [];

		if (onlyPickupLocations) {
			filters.push({
				type: "equals",
				field: "isPickupLocation",
				value: true
			});
		}

		const { data } = await apiInstance.invoke.post<ListingResult<KippieLocation>>(
			`/store-api/pickup-location`,
			{
				filter: filters,
				limit: geoLocation?.value ? 10 : 100
			},
			{
				params: geoLocation?.value && {
					latitude: geoLocation.value.lat,
					longitude: geoLocation.value.lng
				}
			}
		);

		// FIX: Shopware API returns all locations, when coordinates are given because of native mysql query criteria not working
		if (onlyPickupLocations) {
			return data.elements.filter((item) => item.isPickupLocation === true);
		}

		return data.elements;
	}, []);

	if (geoLocation) {
		watch(geoLocation, () => {
			response.execute();
		});
	}

	return response;
};
